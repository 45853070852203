<template>

  <div class="checkout-items">
    <b-card
      v-for="product in products"
      :key="product.product_id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img
            :src="product.product.thumbnails[0]"
            :alt="`${product.product.name}-${product.product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.product.name }}
            </b-link>
          </h6>
          <!-- <span class="item-company">Marca <b-link class="company-name">{{ product.brand }}</b-link></span>-->
        </div>
        <span
          v-if="product.product.stock > 0"
          class="text-success mb-1"
        >En inventario</span>
        <span
          v-else
          class="text-danger mb-1"
        >No disponible</span>
        <div class="item-quantity">
          <span class="quantity-title">Cantidad:</span>
          <b-form-spinbutton
            v-model="product.quantity"
            size="sm"
            class="ml-75"
            inline
            @change="updateProduct(product)"
          />
        </div>
        <div
          v-for="variant in product.variants_labels"
          :key="variant"
          class="d-block"
        >
          <div
            v-if="variant"
          >
            <strong class="quantity-title">{{ getVariant(variant.key) }}: </strong>
            <span class="quantity-title">{{ variant.value }}</span>
          </div>
        </div>
        <span
          v-if="product.quantity >= product.product.qty_free_shipping"
          class="text-success"
        >Envío gratis</span>
        <span
          v-else
          class="text-warning"
        >Envío gratis al comprar mas de {{ product.product.qty_free_shipping }} unidades</span>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              ${{ product.product.price }}
            </h4>
            <p
              v-if="product.quantity >= product.product.qty_free_shipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
              >
                Envío gratis
              </b-badge>
            </p>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProduct(product.id)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Eliminar</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import { VARIANT_LABELS } from '@/config/app'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  props: {
    products: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
    }
  },
  methods: {
    removeProduct(productId) {
      return store.dispatch('products/removeProductFromCart', { item_id: productId })
    },
    updateProduct(product) {
      return store.dispatch('products/updateProductFromCart', { item_id: product.id, quantity: product.quantity })
    },
    getVariant(variant) {
      return VARIANT_LABELS[variant]
    },
  },
}
</script>

<style>

</style>
