<template>
  <div>
    <h3 class="my-1">
      Dirección de envío
    </h3>
    <b-button
      variant="info"
      block
      @click="btnAddDirections"
    >
      <b-icon
        icon="plus-circle"
        aria-hidden="true"
      />{{ textBtnAddDirections }}
    </b-button>
    <div
      v-if="isAddDirections"
    >
      <br>
      <b-card
        v-for="address in addresses"
        :key="address.id"
      >
        <b-card-text class="font-weight-bold">
          {{ address.name }}, {{ address.phone }}
        </b-card-text>
        <b-card-text>{{ address.address }}, Número exterior: {{ address.outdoor_number }}, {{ address.interior_number ? 'Número interior: ' + address.interior_number : '' }}, {{ address.references }}, {{ address.city }}, {{ address.country_id }}, {{ address.postalcode }}</b-card-text>

        <b-row>
          <b-col
            cols="12"
            md="5"
          >
            <b-button
              variant="success"
              block
              class="mb-1"
              @click="$emit('change-address', address.id)"
            >
              Entregar a esta dirección
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              block
              class="mb-1"
              @click="editAddress(address.id)"
            >
              Editar esta dirección
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="danger"
              block
              @click="deleteAddress(address.id)"
            >
              Eliminar
            </b-button>
          </b-col>
        </b-row>

      </b-card>
    </div>
    <div
      v-if="!isAddDirections"
    >
      <br>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="list-view product-checkout"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-card
            title="Agrega tu dirección"
          >
            <b-card-body>
              <b-row>

                <!-- Full Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Nombre de contacto"
                    rules="required"
                  >
                    <b-form-group
                      label="Nombre de contacto"
                      label-for="full-name"
                      class="mb-2"
                    >
                      <b-form-input
                        id="full-name"
                        v-model="addressDetails.name"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Mobile Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Teléfono de contacto"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Teléfono de contacto"
                      label-for="mobile-number"
                    >
                      <b-form-input
                        id="mobile-number"
                        v-model="addressDetails.phone"
                        type="number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Interior Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Número Interno"
                    rules="integer"
                  >
                    <b-form-group
                      label="Número Interno"
                      label-for="interior_number"
                      class="mb-2"
                    >
                      <b-form-input
                        id="interior_number"
                        v-model="addressDetails.interior_number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Outdoor Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Número Externo"
                    rules="integer|required"
                  >
                    <b-form-group
                      label="Número Externo"
                      label-for="outdoor_number"
                      class="mb-2"
                    >
                      <b-form-input
                        id="outdoor_number"
                        v-model="addressDetails.outdoor_number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- pincode -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Código Postal"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Código Postal"
                      label-for="postalcode"
                      class="mb-2"
                    >
                      <b-form-input
                        id="postalcode"
                        v-model="addressDetails.postalcode"
                        type="number"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Suburb -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Colonia"
                    rules="required"
                  >
                    <b-form-group
                      label="Colonia"
                      label-for="suburb"
                      class="mb-2"
                    >
                      <b-form-input
                        id="suburb"
                        v-model="addressDetails.suburb"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- City -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Ciudad"
                    rules="required"
                  >
                    <b-form-group
                      label="Ciudad"
                      label-for="city"
                      class="mb-2"
                    >
                      <b-form-input
                        id="city"
                        v-model="addressDetails.city"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- state -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Estado"
                    rules="required"
                  >
                    <b-form-group
                      label="Estado"
                      label-for="state"
                      class="mb-2"
                    >
                      <b-form-input
                        id="state"
                        v-model="addressDetails.state"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- References -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Referencias"
                    rules="required"
                  >
                    <b-form-group
                      label="Referencias"
                      label-for="references"
                      class="mb-2"
                    >
                      <b-form-input
                        id="references"
                        v-model="addressDetails.references"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Address -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Dirección"
                    rules="required"
                  >
                    <b-form-group
                      label="Dirección"
                      label-for="address"
                      class="mb-2"
                    >
                      <b-form-input
                        id="address"
                        v-model="addressDetails.address"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Submit Button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    Guardar e ir atrás
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardTitle, BRow, BCol, BButton, BIcon, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'AddressSelectorModal',
  components: {
    // BSV
    BForm,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BIcon,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isEditAddress: {
      type: Boolean,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    addressUpdateId: {
      type: Number,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    addressInformation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isAddDirections: !this.isEditAddress,
      isCreated: !this.isEditAddress,
      id: this.addressUpdateId ? this.addressUpdateId : null,
      addressDetails: this.addressInformation ? this.addressInformation : {
        name: '',
        phone: '',
        interior_number: '',
        outdoor_number: '',
        postalcode: '',
        city: '',
        state: '',
        address: '',
        references: '',
        suburb: '',
      },
    }
  },
  computed: {
    addresses() {
      return store.state.address.addresses
    },
    textBtnAddDirections() {
      return this.isAddDirections ? 'Añadir una dirección' : 'Cancelar'
    },
  },
  mounted() {
    this.fetchAddresses()
  },
  methods: {
    fetchAddresses() {
      store.dispatch('address/index')
    },
    btnAddDirections() {
      this.isCreated = true
      this.addressDetails = {
        name: '',
        phone: '',
        interior_number: '',
        outdoor_number: '',
        postalcode: '',
        city: '',
        state: '',
        address: '',
        references: '',
        suburb: '',
      }
      this.isAddDirections = !this.isAddDirections
    },
    onSubmit() {
      const self = this
      const type = this.isCreated ? 'address/store' : 'address/update'
      store.dispatch(type, { params: this.addressDetails, id: this.id }).then(
        () => {
          self.fetchAddresses()
          self.isAddDirections = true
        },
        error => {
          console.log(error)
          return Promise.reject(error)
        },
      )
    },
    getValidationState({
      dirty,
      validated,
      required: fieldRequired,
      changed,
      valid = null,
    }) {
      const result = dirty || validated ? valid : null
      return !fieldRequired && !changed ? null : result
    },
    deleteAddress(id) {
      store.dispatch('address/delete', { id }).then(
        () => {
          this.fetchAddresses()
        },
        error => Promise.reject(error),
      )
    },
    editAddress(id) {
      this.isCreated = false
      const self = this
      store.dispatch('address/show', { id }).then(
        address => {
          self.addressDetails = {
            name: address.name,
            postalcode: address.postalcode,
            city: address.city,
            address: address.address,
            state: address.state,
            phone: address.phone,
            interior_number: address.interior_number,
            outdoor_number: address.outdoor_number,
            references: address.references,
            suburb: address.suburb,
          }
          self.id = address.id
          self.isAddDirections = false
        },
        error => {
          console.log(error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style scoped>

</style>
