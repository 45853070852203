<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products
      v-if="hasCart"
      :products="products"
      @updateCart="updateProductsList"
    />
    <div v-else>
      <p>No hay productos en tu carrito.</p>
      <b-button
        variant="primary"
        to="/products"
      >
        Ver los productos
      </b-button>
    </div>

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <label class="section-label">Opciones</label>
        <div class="price-details">
          <h6 class="price-title">
            Detalles de compra
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Total
              </div>
              <div class="detail-amt">
                ${{ cart.total }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Precio de envío
              </div>
              <div class="detail-amt discount-amt text-success">
                {{ cart.shipping ? '$' + cart.shipping : 'Envío gratis' }}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                {{ formatter.format(hasCart ? parseFloat(Number.isInteger(cart.shipping) ? cart.shipping : cart.shipping.replace(/,/, '')) + parseFloat(cart.total.replace(/,/, '')) : 0.00) }}
              </div>
            </li>
          </ul>
          <b-button
            variant="success"
            block
            :disabled="!hasCart"
            @click="$emit('next-step')"
          >
            Siguiente
          </b-button>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard,
} from 'bootstrap-vue'
import store from '@/store'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  computed: {
    cart() {
      console.log(store.state.products.cart)
      return store.state.products.cart
    },
    products() {
      return store.state.products.cart.items
    },
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
    hasCart() {
      return 'total' in this.cart && this.cart.items.length > 0
    },
  },
  setup() {
    const fetchCartProducts = () => {
      store.dispatch('products/indexCart')
    }
    fetchCartProducts()

    return {
      fetchCartProducts,
    }
  },
  methods: {
    updateProductsList() {
      store.dispatch('products/indexCart')
    },
  },
}
</script>
