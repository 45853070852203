<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Opciones de pago</b-card-title>
        <b-card-text class="text-muted mt-25">
          Asegúrate de dar click en en método correcto
        </b-card-text>
      </b-card-header>
      <b-card-body>

        <!-- Radios -->
        <b-form-group>
          <hr class="mb-2 mt-1">
          <b-form-radio
            v-model="paymentMethod"
            name="payment-method"
            value="1"
          >
            Tarjeta / Crédito / Débito / Oxxo / SPEI a travéz de Conekta
          </b-form-radio>
          <b-form-radio
            v-model="paymentMethod"
            name="payment-method"
            class="mt-1"
            value="2"
          >
            Descuento de nómina
          </b-form-radio>
        </b-form-group>

      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Resumen del pedido">

        <!-- Cart Items -->
        <div
          class="scrollable-container media-list scroll-area mt-2"
          tagname="li"
        >
          <table class="with-table-100">
            <thead>
              <tr
                v-for="item in cart.items"
                :key="item.id"
              >
                <th class="tg-0pky">
                  <img
                    :src="item.product.thumbnails[0]"
                    rounded
                    width="70px"
                  >
                </th>
                <th class="tg-0pky">
                  <div class="media-heading d-inline-block  ml-3">
                    <h6 class="cart-item-title">
                      <b-link class="text-body mt-1">
                        {{ item.product.name }}
                      </b-link>
                    </h6>
                    <!-- <small class="cart-item-by">By LTH</small> -->
                    <small class="cart-item-by">Cantidad: {{ item.quantity }}</small>
                  </div>
                </th>
                <th class="tg-0lax text-right">
                  <h3 class="cart-item-price text-primary d-inline-block ml-3">
                    ${{ item.product.price }}
                  </h3>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div class="checkout-options padding-0-table">
          <b-card class="padding-0-table">

            <div class="price-details">
              <h6 class="price-title">
                Detalles de compra
              </h6>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title">
                    Total
                  </div>
                  <div class="detail-amt">
                    ${{ cart.total }}
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    Precio de envío
                  </div>
                  <div class="detail-amt discount-amt text-success">
                    {{ cart.shipping ? '$' + cart.shipping : 'Gratis' }}
                  </div>
                </li>
              </ul>
              <hr>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total">
                    Total
                  </div>
                  <div class="detail-amt font-weight-bolder">
                    {{ formatter.format(parseFloat(Number.isInteger(cart.shipping) ? cart.shipping : cart.shipping.replace(/,/, '')) + parseFloat(cart.total.replace(/,/, ''))) }}
                  </div>
                </li>
              </ul>
              <b-button
                variant="success"
                block
                :disabled="submitting"
                @click="submitBuy"
              >
                <b-spinner
                  v-if="submitting"
                  small
                />
                Pagar
              </b-button>
            </div>

          </b-card>
        </div>
      </b-card>
    </div>

    <b-modal
      id="conekta"
      ref="redirectConektaModal"
      title="La orden se ha creado con éxito"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      ok-title="Pagar"
    >
      <h6>En 20 segundos seras redireccionado a la página para realizar tu pago, también puedes dar clic en el botón de abajo para ir rápidamente</h6>
      <div>
        <div class="d-block text-center">
          <h6>Pasos para completar tu compra:</h6>
        </div>
        <p>1. Selecciona un método de pago</p>
        <p>2. Ingresa los datos correspondientes</p>
        <p>3. Regresarás a la plataforma automáticamente después de unos segundos y comprobaremos tu pago</p>
      </div>
      <b-button
        variant="primary"
        :href="redirectPage"
      >
        Ir a pagar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BButton, VBModal, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentMethod: 1,
      redirectPage: '',
      submitting: false,
    }
  },
  computed: {
    address() {
      return store.state.address.address
    },
    cart() {
      return store.state.products.cart
    },
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
  },
  methods: {
    submitBuy() {
      if (this.paymentMethod === '2') {
        this.$swal({
          title: '¿Estas seguro?',
          text: '¿Estas de acuerdo que el pago de tu producto se haga mediante un descuento vía nómina?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.submit()
          }
        })
      } else {
        this.submit()
      }
    },
    submit() {
      this.submitting = true
      store.dispatch('products/buy', { params: { address_id: this.address.id, paymentMethod: this.paymentMethod } }).then(
        response => {
          this.submitting = false
          console.log(response)
          if (!response.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertCircleIcon',
                text: response.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 10000,
            })
            return Promise.resolve()
          }
          if (!response.data.redirectUrl) {
            return router.push({ name: 'payment-pending-approval' })
          }
          this.redirectPage = response.data.redirectUrl
          this.$refs.redirectConektaModal.show()
          setTimeout(() => {
            window.location.href = response.data.redirectUrl
          }, 20000)
          return Promise.resolve()
        },
        error => {
          this.submitting = false
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertCircleIcon',
              text: error.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
            timeout: 10000,
          })
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
